<template>
  <div>
    <div class="summary-table" :dir="$direction">
      <TableLoadingSkeleton v-if="!loading" />
      <Table
        v-else
        ref="tableRef"
        :sub-header="isComparison"
        :use-default-wrapper="false"
        :active-sort="activeSort"
        :data="paginatedData"
        :columns="columnState"
        :show-index="(page - 1) * limit + 1"
        sticky-columns="2"
        @row-click="clickedRow"
        @header-action="handleHeaderActions"
      >
        <template
          #sub-header="{
            showIndex,
            hasWidth,
            stickyColumns,
            additionalColumns,
            // eslint-disable-next-line vue/no-template-shadow
            columns,
            getAdditionalColumnStickyBounds,
            isSticky,
            getHeaderStyle,
          }"
        >
          <SubHeader
            custom-index-class-name="sub-header-bg border-right"
            :show-index="showIndex"
            :has-width="hasWidth"
            :sticky-columns="stickyColumns"
            :additional-columns="additionalColumns"
            :columns="columns"
            :get-additional-column-styles="getAdditionalColumnStickyBounds"
            :is-column-index-sticky="isSticky"
            :get-header-style="getHeaderStyle"
          >
            <template #sub-header-quantity="{ column: { width } }">
              <div class="sub-header-comparison">
                <div
                  v-for="index in amountOfComparisons"
                  :key="`sub-avg-label-${index}`"
                  :style="{ width: `${Number(width.split('px')[0]) / amountOfComparisons}px` }"
                >
                  <el-tooltip placement="top" effect="dark" :content="dateRanges[index - 1]">
                    <component :is="getNumberIconComponentName(index)" v-bind="{ size: 16, fill: '#9295A5' }" />
                  </el-tooltip>
                </div>
              </div>
            </template>
            <template #sub-header-avgPeriodPrice="{ column: { width } }">
              <div class="sub-header-comparison">
                <div
                  v-for="index in amountOfComparisons"
                  :key="`sub-avg-label-${index}`"
                  :style="{ width: `${Number(width.split('px')[0]) / amountOfComparisons}px` }"
                >
                  <el-tooltip placement="top" effect="dark" :content="dateRanges[index - 1]">
                    <component :is="getNumberIconComponentName(index)" v-bind="{ size: 16, fill: '#9295A5' }" />
                  </el-tooltip>
                </div>
              </div>
            </template>
            <template #sub-header-totalPurchases="{ column: { width } }">
              <div class="sub-header-comparison">
                <div
                  v-for="index in amountOfComparisons"
                  :key="`sub-total-label-${index}`"
                  :style="{ width: `${Number(width.split('px')[0]) / amountOfComparisons}px` }"
                >
                  <el-tooltip placement="top" effect="dark" :content="dateRanges[index - 1]">
                    <component :is="getNumberIconComponentName(index)" v-bind="{ size: 16, fill: '#9295A5' }" />
                  </el-tooltip>
                </div>
              </div>
            </template>
          </SubHeader>
        </template>
        <template #header-index>
          <div class="table-header-tab">
            <p class="fw-bold d-inline">#</p>
            <div class="table-header-tab-current-period">
              <TableTab>
                <TruncatedText :style="{ width: $direction === 'rtl' ? '170px' : '280px' }">{{
                  $t('terms.supplierTerms.supplierTermTable.simulation.nonSimTableHeader.title')
                }}</TruncatedText>
                <el-tooltip placement="top" effect="dark">
                  <div slot="content" class="table-header-tooltip">
                    <span class="bold"
                      >{{ $t('terms.supplierTerms.supplierTermTable.simulation.nonSimTableHeader.tooltip.bold') }} -
                    </span>
                    <span>{{
                      $t('terms.supplierTerms.supplierTermTable.simulation.nonSimTableHeader.tooltip.regular')
                    }}</span>
                  </div>
                  <SimulationQuestionMarkIcon class="tooltip-icon" />
                </el-tooltip>
              </TableTab>
            </div>
          </div>
        </template>

        <template #[firstVisibleColumnInSimulationCategory]="{ column: { header } }">
          <div class="table-header-tab">
            <p class="fw-bold d-inline">{{ header }}</p>
            <div class="table-header-tab-simulation">
              <TableTab>
                <p>{{ $t('terms.supplierTerms.supplierTermTable.simulation.simTableHeader.title') }}</p>
                <el-tooltip placement="top" effect="dark">
                  <div slot="content" class="table-header-tooltip">
                    <span class="bold"
                      >{{ $t('terms.supplierTerms.supplierTermTable.simulation.simTableHeader.tooltip.sim.bold') }} -
                    </span>
                    <span>{{
                      $t('terms.supplierTerms.supplierTermTable.simulation.simTableHeader.tooltip.sim.regular')
                    }}</span>
                    <br />
                    <br />
                    <span class="bold"
                      >{{
                        $t('terms.supplierTerms.supplierTermTable.simulation.simTableHeader.tooltip.constantPrice.bold')
                      }}
                      -
                    </span>
                    <span>{{
                      $t(
                        'terms.supplierTerms.supplierTermTable.simulation.simTableHeader.tooltip.constantPrice.regular'
                      )
                    }}</span>
                    <br />
                    <br />
                    <span class="bold"
                      >{{
                        $t('terms.supplierTerms.supplierTermTable.simulation.simTableHeader.tooltip.difference.bold')
                      }}
                      -
                    </span>
                    <span>{{
                      $t('terms.supplierTerms.supplierTermTable.simulation.simTableHeader.tooltip.difference.regular')
                    }}</span>
                  </div>
                  <SimulationQuestionMarkIcon class="tooltip-icon" />
                </el-tooltip>
              </TableTab>
            </div>
          </div>
        </template>

        <template #[firstVisibleColumnInPreviousPeriodCategory]="{ column: { header } }">
          <div class="table-header-tab">
            <p class="fw-bold d-inline">{{ header }}</p>
            <div class="table-header-tab-previous-period">
              <TableTab>
                <TruncatedText :style="{ width: previousPeriodTabWidth }">{{
                  $t('terms.supplierTerms.supplierTermTable.simulation.previousPeriodPurchasesHeader.title')
                }}</TruncatedText>
                <el-tooltip placement="top" effect="dark">
                  <div slot="content" class="table-header-tooltip">
                    <span class="bold">{{ $t('terms.supplierTerms.suppliersTable.summary.leftHeader.title') }} - </span>
                    <span>{{ $t('terms.supplierTerms.suppliersTable.summary.leftHeader.desc') }}</span>
                  </div>
                  <SimulationQuestionMarkIcon class="tooltip-icon" />
                </el-tooltip>
              </TableTab>
            </div>
          </div>
        </template>

        <template v-for="(cellConfiguration, index) in cellConfigurations" #[cellConfiguration.slotName]="{ rowData }">
          <component
            :is="cellConfiguration.componentName"
            :key="`cellConf${index}`"
            :set="(data = getComparisonItem(rowData, 0, true))"
            v-bind="getCellRendererProps(rowData, data, cellConfiguration)"
          />
        </template>
        <template #cell-purchaseList="{ rowData }">
          <PurchaseListCell
            v-if="getRowData(rowData)?.product?.id"
            :row-data="getRowData(rowData)"
            @on-change-purchase-list-status="(choice) => onChangePurchaseListStatus(choice, rowData.product.id)"
          />
          <p v-else>{{ MISSING_DATA_TEXT }}</p>
        </template>
        <template #cell-constantPrice="{ rowData }">
          <SimulationPriceCell
            :row-data="getRowData(rowData)"
            :selected-simulation-id="selectedSimulationId"
            @on-toggle-popover="selectedSimulationId = null"
            @on-update-simulation-product="(productId) => (selectedSimulationId = productId)"
            @on-apply="({ id: pId, args }) => onApplySimulation(pId, args)"
          />
        </template>
        <template #actions-constantPrice>
          <el-dropdown-menu slot="dropdown">
            <div>
              <p class="dropdown-title">
                {{ $t('terms.supplierTerms.supplierTermTable.simulation.applyOnEverything') }}
              </p>
              <div class="divider"></div>
            </div>
            <el-dropdown-item
              v-for="(priceMode, index) in priceModes"
              :key="`price-mode-${index}`"
              :command="priceMode.name"
              :style="{ display: priceMode.notAll ? 'none' : '' }"
            >
              <div
                v-if="
                  priceMode.name !== PRICE_MODE_NAMES.PREV_PERIOD_AVG_PRICE &&
                  priceMode.name !== PRICE_MODE_NAMES.DISABLED
                "
                class="d-flex gap-1 align-items-center"
              >
                <SimulationArrowIcon :name="`price-mode-icon-${index}`" :class="priceMode.arrowDir" />
                <p>{{ $t(priceMode.label) }}</p>
              </div>
              <div
                v-else-if="priceMode.name == PRICE_MODE_NAMES.PREV_PERIOD_AVG_PRICE"
                class="d-flex gap-1 align-items-center"
              >
                <SimulationPreviousAverageIcon :name="`price-mode-icon-${index}`" :size="16" />
                <p>{{ $t(priceMode.label) }}</p>
              </div>
              <div v-else-if="priceMode.name == PRICE_MODE_NAMES.DISABLED" class="d-flex gap-1 align-items-center">
                <SimulationDisabledIcon :name="`price-mode-icon-${index}`" :size="16" />
                <p>{{ $t(priceMode.label) }}</p>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </Table>
    </div>
    <el-pagination
      v-if="mutatedProductItems.length > limit"
      small
      layout="prev, pager, next, jumper"
      :current-page.sync="page"
      background
      :page-size="limit"
      :page-count="Math.ceil(mutatedProductItems.length / limit)"
      class="my-2 float-right pb-4"
    />
  </div>
</template>
<script>
import { computed, ref, watch, toRefs, onBeforeMount, onBeforeUnmount } from 'vue';
import { DateTime } from 'luxon';
import { clone } from 'ramda';

import { useCurrency } from '@/locale/useCurrency';

import { LESS_THAN_LTR, DISPLAY_MODES, MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import {
  mutatedSimulationProductItems,
  mutateProductComparisonItems,
  calculatePercentageChangeInPrice,
  getDifferenceForPeriod,
  getAnnualImpact,
} from './supplierProductsTable';
import {
  SUPPLIER_PRODUCTS_TABLE_HEADERS,
  PRICE_MODE_NAMES,
  COLUMN_CATEGORIES,
  priceModes,
} from './supplierProductsTableConstants';
import { paginatedData, calculateProductsSum, getNumberIconComponentName } from '../../purchaseManagement';
import { getDateRangeStringFromDates } from '../../purchaseManagementFormatters';
import {
  getSummaryCellConfiguration,
  getComparisonCellConfiguration,
  getPreviousPeriodCellConfiguration,
  getSimulationCellConfiguration,
  DATA_TO_RETRIEVE,
} from './supplierProductsCellRenderers';
import {
  SimulationQuestionMarkIcon,
  SimulationTableDropdownArrowIcon,
  SimulationTableArrowUpIcon,
  SimulationTableArrowDownIcon,
  SimulationArrowIcon,
  SimulationPreviousAverageIcon,
  SimulationDisabledIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
} from '@/assets/icons';
import { openProductModal } from '@/modules/products/store/product-modal';
import { Table, TruncatedText, TableLoadingSkeleton } from '@/modules/core/components';
import PercentageChange from '../PercentageChange';
import SubHeader from '@/modules/core/components/SubHeader';
import TableTab from '../TableTab.vue';
import {
  FormattedParagraphCell,
  SkuCell,
  ProductNameCell,
  AmountOfAssociatedCell,
  AveragePeriodPriceCell,
  PreviousPeriodDifferenceCell,
  AnnualImpactCell,
  SimulationPriceCell,
  FormattedParagraphComparisonCell,
  TotalPurchasesComparisonCell,
  DiscountCell,
  PurchaseListCell,
} from './supplierTableCells';
import { getIsRecommendedByPurchaseListStatus } from './supplierTableCells/PurchaseListCell';

export default {
  components: {
    Table,
    TruncatedText,
    SimulationQuestionMarkIcon,
    SimulationTableArrowDownIcon,
    SimulationTableDropdownArrowIcon,
    SimulationTableArrowUpIcon,
    SimulationArrowIcon,
    SimulationPreviousAverageIcon,
    OneIcon,
    TwoIcon,
    ThreeIcon,
    SimulationDisabledIcon,
    TableLoadingSkeleton,
    PercentageChange,
    SubHeader,
    TableTab,
    DiscountCell,
    FormattedParagraphCell,
    SkuCell,
    ProductNameCell,
    AmountOfAssociatedCell,
    AveragePeriodPriceCell,
    PreviousPeriodDifferenceCell,
    AnnualImpactCell,
    SimulationPriceCell,
    FormattedParagraphComparisonCell,
    TotalPurchasesComparisonCell,
    PurchaseListCell,
  },
  props: {
    supplierProductsOverview: { type: Object, required: true },
    loading: { type: Boolean, required: true },
    columns: { type: Array, required: true },
    dateRanges: { type: Array, required: true },
    previousPeriodDates: { type: Object, required: true },
    searchProductText: { type: String, required: true },
    amountOfComparisons: { type: Number, default: 0 },
    purchaseListFilter: { type: String, default: null },
  },
  emits: ['on-column-update', 'on-update-mutated-data', 'on-total-update', 'on-purchase-list-status-update'],
  setup(props, { emit }) {
    const { currencyFormat } = useCurrency();

    const page = ref(1);
    const limit = ref(25);
    const activeSort = ref({
      direction: -1,
      columnKey: SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_PURCHASES,
    });
    const columnState = toRefs(props).columns;
    const defaultColumns = ref({});
    const openSettingsModal = ref(false);
    const isLoading = toRefs(props).loading;
    const supplierProductsOverviewReactive = ref(null);
    const selectedSimulationId = ref(null);

    const isComparison = computed(() => props.amountOfComparisons > 1);
    const supplierProductsOverviewComputed = computed(() => {
      if (!isLoading.value) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        supplierProductsOverviewReactive.value = null;
        return props.supplierProductsOverview;
      }
      return supplierProductsOverviewReactive.value ?? props.supplierProductsOverview;
    });

    const mutatedProductItemsComputed = computed(() => {
      if (!supplierProductsOverviewComputed.value) return [];

      if (isComparison.value && supplierProductsOverviewComputed.value.secondSummaryQuery) {
        const simulationItems = mutatedSimulationProductItems({
          simulationAggregation: supplierProductsOverviewComputed.value,
          activeSort: activeSort.value,
          productSearchFilter: props.searchProductText,
        });
        const comparisonItems = mutateProductComparisonItems(
          supplierProductsOverviewComputed.value,
          activeSort.value,
          props.searchProductText
        );

        const simulationMappedItems = comparisonItems.map((comparisonItem) => {
          const simulationItem = simulationItems.find(
            (simulationItem) => simulationItem.product.id === comparisonItem['comparison-0'].product.id
          );
          return { ...comparisonItem, 'comparison-0': simulationItem };
        });

        if (props.purchaseListFilter) {
          const valueForIsRecommendedToFilterBy = getIsRecommendedByPurchaseListStatus(props.purchaseListFilter);
          return simulationMappedItems.filter((row) =>
            row['comparison-0'] ? row['comparison-0'].isRecommended === valueForIsRecommendedToFilterBy : false
          );
        }

        return simulationMappedItems;
      }
      const simulationMappedItems = mutatedSimulationProductItems({
        simulationAggregation: supplierProductsOverviewComputed.value,
        activeSort: activeSort.value,
        productSearchFilter: props.searchProductText,
      });

      if (props.purchaseListFilter) {
        const valueForIsRecommendedToFilterBy = getIsRecommendedByPurchaseListStatus(props.purchaseListFilter);
        return simulationMappedItems.filter((row) => row.isRecommended === valueForIsRecommendedToFilterBy);
      }

      return simulationMappedItems;
    });

    const firstVisibleColumnInPreviousPeriodCategory = computed(() => {
      const onlyShownColumns = columnState.value.filter((value) => !value.hidden);
      const productsPurchasedColumns = onlyShownColumns.filter(
        (columns) => columns.category === COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED
      );
      if (productsPurchasedColumns.length === 0) return '';

      return `header-${productsPurchasedColumns[0].key}`;
    });

    const firstVisibleColumnInSimulationCategory = computed(() => {
      const onlyShownColumns = columnState.value.filter((value) => !value.hidden);
      const simulationColumns = onlyShownColumns.filter((columns) => columns.category === COLUMN_CATEGORIES.SIMULATION);
      if (simulationColumns.length === 0) return '';

      return `header-${simulationColumns[0].key}`;
    });

    const paginatedDataComputed = computed(() =>
      paginatedData({ mutatedProductItems: mutatedProductItemsComputed.value, page: page.value, limit: limit.value })
    );

    const previousPeriodDateString = computed(() => {
      const fromDate = DateTime.fromISO(props.previousPeriodDates.previousPeriodFromDate);
      const toDate = DateTime.fromISO(props.previousPeriodDates.previousPeriodToDate);

      return getDateRangeStringFromDates(fromDate, toDate);
    });

    const sumPurchasesComputed = computed(() => {
      const sumPurchasesForComparison = [];
      const supplierOverviewKeys = Object.keys(supplierProductsOverviewComputed.value).filter(
        (key) => key !== 'previousPeriodPurchaseSummary'
      );
      for (let i = 0; i < props.amountOfComparisons; i++) {
        sumPurchasesForComparison.push(
          calculateProductsSum(supplierProductsOverviewComputed.value[supplierOverviewKeys[i]])
        );
      }
      const totalDifference = mutatedProductItemsComputed.value.reduce((a, value) => {
        const rowData = getComparisonItem(value, 1, true);
        if (!rowData) return a;
        const totalPeriodDifference = rowData.totalPeriodDifference ?? 0;
        return totalPeriodDifference + a;
      }, 0);

      const totalDifferenceSim = mutatedProductItemsComputed.value.reduce((a, value) => {
        const rowData = getComparisonItem(value, 1, true);
        if (!rowData) return a;
        const totalPeriodDifference = rowData.totalDifference ?? 0;
        return totalPeriodDifference + a;
      }, 0);

      const totalPurchasesBySim = mutatedProductItemsComputed.value.reduce((a, value) => {
        const rowData = getComparisonItem(value, 1);
        if (!rowData) return a;
        const sumPrice = rowData.simulatedPrice * rowData.quantity;
        return sumPrice + a;
      }, 0);

      emit('on-total-update', {
        totalDifference,
        totalPurchases: sumPurchasesForComparison[0],
        totalDifferenceSim,
        totalPurchasesBySim,
      });

      return sumPurchasesForComparison;
    });

    const calculatePercentage = (sumPrice, total) => (sumPrice / total) * 100;

    const previousPeriodTabWidth = computed(() => {
      const previousPeriod = columnState.value.filter(
        (column) => !column.hidden && column.category === COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED
      );

      if (previousPeriod.length > 1) {
        return '260px';
      } else {
        const width = Number(previousPeriod[0].width.split('px')[0]);
        return `${width - 60}px`;
      }
    });

    const customClassesState = computed(() => {
      const onlyShownColumns = columnState.value.filter((value) => !value.hidden);
      const productsPurchased = onlyShownColumns.filter(
        (columns) => columns.category === COLUMN_CATEGORIES.PRODUCTS_PURCHASED
      );
      const previousPeriod = onlyShownColumns.filter(
        (columns) => columns.category === COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED
      );
      const simulation = onlyShownColumns.filter((columns) => columns.category === COLUMN_CATEGORIES.SIMULATION);
      const minOrMaxPriceShown = productsPurchased.some(
        (column) =>
          column.key === SUPPLIER_PRODUCTS_TABLE_HEADERS.MIN_PRICE ||
          column.key === SUPPLIER_PRODUCTS_TABLE_HEADERS.MAX_PRICE
      );
      const productsPurchasedColumns = productsPurchased.map((categoryColumn, index) => {
        const comparisonBorder = isComparison.value && categoryColumn.isComparisonColumn ? 'border-right' : '';
        if (
          isComparison.value &&
          minOrMaxPriceShown &&
          categoryColumn.key === SUPPLIER_PRODUCTS_TABLE_HEADERS.AVG_PERIOD_PRICE
        ) {
          const comparisonAveragePriceBorder = 'border-left-right';
          return {
            ...categoryColumn,
            customClass: comparisonAveragePriceBorder,
            subHeaderCustomClass: `sub-header-bg ${comparisonAveragePriceBorder}`,
          };
        }
        if (index === productsPurchased.length - 1 && simulation.length === 0 && previousPeriod.length === 0) {
          const comparisonLastBorder = comparisonBorder === '' ? 'border-left' : 'border-left-right';

          return {
            ...categoryColumn,
            customClass: comparisonLastBorder,
            subHeaderCustomClass: `sub-header-bg ${comparisonLastBorder}`,
          };
        }

        return {
          ...categoryColumn,
          customClass: comparisonBorder,
          subHeaderCustomClass: `sub-header-bg ${comparisonBorder}`,
        };
      });
      const previousPeriodColumns = previousPeriod.map((categoryColumn, index) => {
        if (index === 0 && index === previousPeriod.length - 1 && simulation.length === 0) {
          return {
            ...categoryColumn,
            customClass: 'border-left-right',
          };
        }
        if (index === 0) {
          return {
            ...categoryColumn,
            customClass: 'border-right',
            subHeaderCustomClass: 'sub-header-bg border-right',
          };
        }

        if (index === previousPeriod.length - 1 && simulation.length === 0) {
          return {
            ...categoryColumn,
            customClass: 'border-left',
            subHeaderCustomClass: 'sub-header-bg border-left',
          };
        }

        return {
          ...categoryColumn,
          customClass: '',
        };
      });
      const simulationColumns = simulation.map((categoryColumn, index) => {
        if (index === 0 && simulation.length === 1) {
          return {
            ...categoryColumn,
            customClass: 'border-left-right',
          };
        }

        if (index === 0) {
          return {
            ...categoryColumn,
            customClass: 'border-right',
            subHeaderCustomClass: 'sub-header-bg border-right',
          };
        }

        if (index === simulation.length - 1) {
          return {
            ...categoryColumn,
            customClass: 'border-left',
            subHeaderCustomClass: 'sub-header-bg border-left',
          };
        }

        return {
          ...categoryColumn,
          customClass: '',
        };
      });
      return [...productsPurchasedColumns, ...previousPeriodColumns, ...simulationColumns];
    });

    const getCellRendererProps = (rowData, data, cellConfiguration) => {
      if (cellConfiguration.dataToRetrieve === DATA_TO_RETRIEVE.ROW) {
        return {
          rowData: cellConfiguration.comparisonCell ? rowData : data,
          ...cellConfiguration.extraProps,
        };
      }

      if (cellConfiguration.dataToRetrieve === DATA_TO_RETRIEVE.ORDER_ITEM) {
        return {
          orderItem: data ? data.maxDateOrderItem : undefined,
          ...cellConfiguration.extraProps,
        };
      }

      return {
        data:
          cellConfiguration.dataSelectorForGenericCell && data
            ? data[cellConfiguration.dataSelectorForGenericCell]
            : undefined,
        ...cellConfiguration.extraProps,
      };
    };

    const cellConfigurations = computed(() => {
      return [
        ...getComparisonCellConfiguration({
          getComparisonItem,
          amountOfComparisons: props.amountOfComparisons,
          getWidth,
          sumPurchases: sumPurchasesComputed.value,
          calculatePercentage,
          currencyFormat: currencyFormat.value,
        }),
        ...getSummaryCellConfiguration(currencyFormat.value),
        ...getPreviousPeriodCellConfiguration(currencyFormat.value),
        ...getSimulationCellConfiguration(currencyFormat.value),
      ];
    });

    const getComparisonItem = (comparisonItem, index, forceValue = false) => {
      if (!isComparison.value) return comparisonItem;

      let returnItem = comparisonItem[`comparison-${index - 1}`];

      if (!forceValue) return returnItem;

      for (let i = 0; i < props.amountOfComparisons; i++) {
        if (returnItem?.product) break;

        returnItem = comparisonItem[`comparison-${i}`];
      }
      return returnItem;
    };

    const getWidth = (key) => {
      return columnState.value.find((column) => column.key === key).width;
    };

    const clickedRow = (rowClicked) => {
      const product = getComparisonItem(paginatedDataComputed.value[rowClicked], 1, true);
      openProductModal(product.product.id);
    };

    const onApplySimulation = (id, { newPrice, newMode }) => {
      const tableDataCopy = clone(supplierProductsOverviewComputed.value.productPurchaseSummary);
      const productIndex = supplierProductsOverviewComputed.value.productPurchaseSummary.findIndex(
        (value) => value.product.id === id
      );
      if (productIndex == -1) return null;

      tableDataCopy[productIndex] = {
        ...tableDataCopy[productIndex],
        simulatedPrice: newPrice,
        rendered: true,
        simulationMode: newMode,
      };

      supplierProductsOverviewReactive.value = {
        ...supplierProductsOverviewComputed.value,
        productPurchaseSummary: tableDataCopy,
      };
    };

    const changePriceForAllTable = (priceKey, simulationMode) => {
      const tableDataCopy = clone(supplierProductsOverviewComputed.value.productPurchaseSummary);
      const mutatedTableData = tableDataCopy.map((row) => {
        if (priceKey === PRICE_MODE_NAMES.DISABLED) {
          return { ...row, simulatedPrice: null, rendered: true };
        }

        let price;
        if (priceKey === PRICE_MODE_NAMES.PREV_PERIOD_AVG_PRICE) {
          const productId = row.product.id;
          const previousAverage = supplierProductsOverviewComputed.value.previousPeriodPurchaseSummary.find(
            (previousAverageProduct) => previousAverageProduct.productId === productId
          );
          const previousAveragePrice = previousAverage ? previousAverage.pricedOrderItemAverage : null;
          price = previousAveragePrice;
        } else {
          price = row[priceKey]?.netAmountPerUnit;
        }

        return { ...row, simulatedPrice: price, rendered: true, simulationMode };
      });

      supplierProductsOverviewReactive.value = {
        ...supplierProductsOverviewComputed.value,
        productPurchaseSummary: mutatedTableData,
      };
    };

    const hidePopover = () => {
      if (selectedSimulationId.value) {
        selectedSimulationId.value = null;
      }
    };

    const handleHeaderActions = (command) => {
      let priceKey;
      switch (command.command) {
        case PRICE_MODE_NAMES.FIRST_PRICE:
          priceKey = 'minDateOrderItem';
          break;
        case PRICE_MODE_NAMES.LAST_PRICE:
          priceKey = 'maxDateOrderItem';
          break;
        case PRICE_MODE_NAMES.HIGHEST_PRICE:
          priceKey = 'maxPriceOrderItem';
          break;
        case PRICE_MODE_NAMES.LOWEST_PRICE:
          priceKey = 'minPriceOrderItem';
          break;
        case PRICE_MODE_NAMES.PREV_PERIOD_AVG_PRICE:
          priceKey = 'previousAveragePrice';
          break;
        case PRICE_MODE_NAMES.DISABLED:
          priceKey = 'disabled';
          break;
        default:
          priceKey = null;
          break;
      }

      changePriceForAllTable(priceKey, command.command);
    };

    const toggleModal = () => {
      openSettingsModal.value = !openSettingsModal.value;
    };

    const getRowData = (rowData) => {
      if (isComparison.value) {
        return rowData ? rowData['comparison-0'] : null;
      }

      return rowData;
    };

    const onChangePurchaseListStatus = (choice, productId) => {
      const tableDataCopy = clone(supplierProductsOverviewComputed.value.productPurchaseSummary);
      const isRecommendedToOverride = getIsRecommendedByPurchaseListStatus(choice);
      const mutatedTableData = tableDataCopy.map((row) => {
        if (row?.product?.id === productId) {
          return {
            ...row,
            isRecommended: isRecommendedToOverride,
          };
        }

        return row;
      });

      supplierProductsOverviewReactive.value = {
        ...supplierProductsOverviewComputed.value,
        productPurchaseSummary: mutatedTableData,
      };
      emit('on-purchase-list-status-update', { isRecommended: isRecommendedToOverride, productId });
    };

    onBeforeMount(() => {
      const div = document.getElementById('router-container');
      div.addEventListener('scroll', hidePopover);
    });

    onBeforeUnmount(() => {
      const div = document.getElementById('router-container');
      div.removeEventListener('scroll', hidePopover);
    });

    watch(isLoading, () => {
      page.value = 1;
    });

    watch(mutatedProductItemsComputed, () => {
      emit('on-update-mutated-data', { data: mutatedProductItemsComputed.value });
    });

    return {
      DISPLAY_MODES,
      MISSING_DATA_TEXT,
      LESS_THAN_LTR,
      SUPPLIER_PRODUCTS_TABLE_HEADERS,
      PRICE_MODE_NAMES,
      page,
      limit,
      defaultColumns,
      columnState: customClassesState,
      openSettingsModal,
      selectedSimulationId,
      previousPeriodTabWidth,
      activeSort,
      isComparison,
      mutatedProductItems: mutatedProductItemsComputed,
      paginatedData: paginatedDataComputed,
      sumPurchases: sumPurchasesComputed,
      firstVisibleColumnInPreviousPeriodCategory,
      firstVisibleColumnInSimulationCategory,
      previousPeriodDateString,
      priceModes,
      cellConfigurations,
      getRowData,
      clickedRow,
      getWidth,
      getComparisonItem,
      onApplySimulation,
      getNumberIconComponentName,
      calculatePercentage,
      handleHeaderActions,
      getCellRendererProps,
      getDifferenceForPeriod,
      hidePopover,
      getAnnualImpact,
      calculatePercentageChangeInPrice,
      onChangePurchaseListStatus,
      openProductModal,
      toggleModal,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .table-hover > tbody > tr:hover > * {
    background-color: #f8fafb;
  }
  th {
    box-shadow: inset 0 1px 0 0 #e5e8ea;
  }
  th.header-index {
    [dir='ltr'] & {
      box-shadow: inset 1px 1px 0 0 #e5e8ea;
    }
    [dir='rtl'] & {
      box-shadow: inset -1px 1px 0 0 #e5e8ea;
    }
  }
  td.cell-index {
    [dir='ltr'] & {
      box-shadow: inset 1px 0 0 0 #e5e8ea;
    }
    [dir='rtl'] & {
      box-shadow: inset -1px 0 0 0 #e5e8ea;
    }
  }
  th.border-right {
    [dir='ltr'] & {
      box-shadow: inset 1px 1px 0 0 #e5e8ea;
    }
    [dir='rtl'] & {
      box-shadow: inset -1px 1px 0 0 #e5e8ea;
    }
  }
  td.border-right {
    [dir='ltr'] & {
      box-shadow: inset 1px 0 0 0 #e5e8ea;
    }
    [dir='rtl'] & {
      box-shadow: inset -1px 0 0 0 #e5e8ea;
    }
  }
  th.border-left {
    [dir='ltr'] & {
      box-shadow: inset -1px 1px 0 0 #e5e8ea;
    }
    [dir='rtl'] & {
      box-shadow: inset 1px 1px 0 0 #e5e8ea;
    }
  }
  td.border-left {
    [dir='ltr'] & {
      box-shadow: inset -1px 0 0 0 #e5e8ea;
    }
    [dir='rtl'] & {
      box-shadow: inset 1px 0 0 0 #e5e8ea;
    }
  }
  th.border-left-right {
    [dir='ltr'] & {
      border-right: 1px solid #e5e8ea;
      box-shadow: inset 1px 1px 0 0 #e5e8ea;
    }
    [dir='rtl'] & {
      border-left: 1px solid #e5e8ea;
      box-shadow: inset -1px 1px 0 0 #e5e8ea;
    }
  }
  td.border-left-right {
    [dir='ltr'] & {
      border-right: 1px solid #e5e8ea;
      box-shadow: inset 1px 0 0 0 #e5e8ea;
    }
    [dir='rtl'] & {
      border-left: 1px solid #e5e8ea;
      box-shadow: inset -1px 0 0 0 #e5e8ea;
    }
  }
  .bg-neutral {
    background-color: #fbfbfb;
  }
  .table-responsive {
    width: fit-content;
  }
  .sub-header-bg {
    height: 24px;
    padding: 0.2rem 1rem;
    background-color: #fbfbfb;
  }
}

.border-right {
  [dir='ltr'] & {
    border-left: 1px solid #e5e8ea;
  }
  [dir='rtl'] & {
    border-right: 1px solid #e5e8ea;
  }
}

.summary-table {
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 6px;
  border-bottom: 1px solid #d9dcde;
  padding-top: 45px;
}
.table-header-tooltip {
  width: 244px;
}
.percentage-change-tooltip {
  width: 194px;
}
.annual-impact {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.average-period-price {
  display: flex;
  gap: 0.5rem;
}
.bold {
  font-weight: 500;
}

.table-header-tab {
  position: relative;
  .table-header-tab-previous-period {
    height: 39px;
    position: absolute;
    top: -47px;
    width: 330px;

    [dir='rtl'] & {
      right: -16px;
    }
    [dir='ltr'] & {
      left: -16px;
    }
  }

  .table-header-tab-simulation {
    height: 39px;
    position: absolute;
    top: -47px;
    width: 200px;

    [dir='rtl'] & {
      right: -16px;
    }
    [dir='ltr'] & {
      left: -16px;
    }
  }

  .table-header-tab-current-period {
    position: absolute;
    height: 39px;
    top: -47px;

    [dir='rtl'] & {
      right: -16px;
      width: 235px;
    }
    [dir='ltr'] & {
      left: -16px;
      width: 350px;
    }
  }
}
.up {
  transform: rotate(0);
}
.down {
  transform: rotate(180deg);
}
.right {
  transform: rotate(90deg);
}
.left {
  transform: rotate(270deg);
}
.divider {
  background: #d9dcde;
  width: 100%;
  height: 1px;
  margin-top: 4px;
}

.dropdown-title {
  font-weight: 500;
  font-size: 16px;
  padding: 0px 20px;
}
.sub-header-comparison {
  display: flex;
}
</style>
